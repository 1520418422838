body {
  font-family: "Georgia", serif;
  background-color: #f8f9fa;
  color: #333;
  margin: 0;
  padding: 0;
  text-align: left;
  min-height: 100vh;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.container {
  background-color: white;
  padding: 30px 50px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  margin: 30px auto; /* Center the content and add space from the top */
  overflow: hidden;
}

h1 {
  color: #6a0dad;
  font-size: 24px; /* Made this smaller */
  margin-bottom: 20px;
}

.strikethrough {
  text-decoration: line-through;
}

p {
  line-height: 1.8;
  font-size: 18px;
  margin-bottom: 15px;
  text-align: left;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

ul li {
  margin-bottom: 10px;
}

.signature {
  margin-top: 40px;
  font-style: italic;
  color: #6a0dad;
  font-size: 18px;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #6a0dad;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #500a8f;
}

.image-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  height: auto; /* Auto height to allow image to scale naturally */
  margin-bottom: 20px;
  margin: 0 auto; /* Center the image */
}

.shimmer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 200% 100%;
  animation: shimmer 2s infinite;
  z-index: 1;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
  display: block;
}

a {
  color: #6a0dad;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Add padding at the bottom to ensure scrolling works */
.container::after {
  content: "";
  display: block;
  height: 50px;
}
